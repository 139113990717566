.kanbanView {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  overflow: auto;

  height: calc(100vh - 110px);
}

.kanbanView .columnSection {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-right: 12px;
  background-color: #ebebee;
  /* border-top: 6px solid rgb(213, 215, 217); */
  border-top: 6px solid #57C5B6;
  border-radius: 6px;
}
.kanbanView .columnSection:nth-last-child(1) {
  margin-right: 0;
}

.kanbanView .columnSection .columnHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 12px);
  padding: 2px 5px 10px;
}
.columnSection .columnHeader .columnHeader__title {
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
}

.columnSection .columnHeader .columnHeader__qty {
  width: 20px;
  font-size: 10px;
  background-color: #fff5fd;
  text-align: center;
  padding: 2px 4px;
  border: none;
  border-radius: 10px;
}

.columnSection .columnMain {
  border-radius: 0 0 6px 6px;
}
.columnSection .columnMain .columnMain__container {
  padding: 6px;
  width: 250px;
  background-color: transparent;
  /* min-height: calc(100vh - 170px); */
  /* height: calc(100vh - 165px); */
  /* overflow: auto; */
}
.columnSection .columnMain .columnMain__container::-webkit-scrollbar-track {
  background-color: transparent;
}

.columnSection .columnMain .columnMain__card {
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  min-height: 50px;
  border-radius: 5px;
}
