body {
	background-color: #f8f9fa;
}

.shadow__box * {
	box-sizing: border-box;
}

.shadow__box {
	background-color: #fff;
	box-shadow: rgb(0 0 0 / 5%) 0px 20px 27px 0px;
	border-radius: 8px;
	padding: 12px;
	margin: 8px;

	font-size: 12px;
}

.shadow__box .shadow__box__title {
	font-size: 14px;
	font-weight: 600;

	display: flex;
	justify-content: space-between;
	align-items: center;
}

.shadow__box .shadow__box__action {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.shadow__box .shadow__box__action .shadow__box__action-item {
	margin-left: 8px;
	transition: all 0.2s ease-in-out;
	padding: 8px;

	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.shadow__box .shadow__box__action .shadow__box__action-item.filter__select__action-item {
	width: 24px;
	height: 24px;

	padding: 4px;
}

.shadow__box .shadow__box__action .shadow__box__action-item:hover {
	cursor: pointer;
	transform: scale(1.1);
	background-color: #f8f9fa;
	border-radius: 50%;
}

.shadow__box input.shadow__box__search {
	width: 100%;
	border: none;
	outline: none;
	padding: 8px 0;
	border-radius: 0;
	margin-bottom: 8px;

	border-bottom: 1px solid #eee;
}

.shadow__box .filter__check__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.shadow__box .filter__check__list .filter__check__list-item {
	margin-top: 8px;

	display: flex;
	align-items: center;
}

.shadow__box .filter__check__list .filter__check__list-item .filter__check__list-item-drag {
	opacity: 0.4;
	transition: all 0.2s ease-in-out;
}

.shadow__box .filter__check__list .filter__check__list-item:hover .filter__check__list-item-drag {
	opacity: 1;
	cursor: grab;
}

.shadow__box .filter__check__list .filter__check__list-item label {
	margin-left: 8px;
	display: flex;
	align-items: center;
	flex: 1;
}

.shadow__box .filter__check__list .filter__check__list-item label.empty {
	color: #999;
	font-weight: 500;
	text-align: center;
	display: block;
	width: 100%;
}

.shadow__box .filter__check__list .filter__check__list-item label svg {
	width: 20px;
	height: 20px;
	padding: 3px;
	border-radius: 50%;
	margin-left: 8px;
	transition: all 0.3s;
	transform: scale(0.9);
}

.shadow__box .filter__check__list .filter__check__list-item label > svg:first-child {
	margin-left: auto;
	visibility: hidden;
}
.shadow__box .filter__check__list .filter__check__list-item:hover label > svg:first-child {
	visibility: visible !important;
}

.shadow__box .filter__check__list .filter__check__list-item label svg:hover {
	background-color: #eee;
	transform: scale(1);
	cursor: pointer;
}

.shadow__box .filter__check__list .filter__check__list-item .Mui-checked + label {
	font-weight: bold;
}

.shadow__box .filter__check__list--sub {
	margin-left: 24px;
}

.shadow__box .shadow__box__content {
	margin-top: 8px;
}

.product__filter__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.product__filter__row-select::before,
.product__filter__row-select::after {
	display: none;
}
