.column-start {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
}
.flex-start {
	display: flex;
	justify-content: start;
	align-items: center;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-top {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.box_new_voucher {
	width: 100%;
	height: 85vh;
}

.box_tab_detail_vouvher__content {
	max-width: 100%;
	width: inherit;
}
/* Set up table */
.box_new_voucher .rdt_Table,
.box_tab_detail_vouvher__content .rdt_Table {
	background-color: transparent;
	border-radius: 0;
}
/* .box_voucher__body__content .rdt_TableHead {
	width: calc(100% - 8px);
	border-right: 1px solid #ccc;
} */
.box_new_voucher .rdt_TableHeadRow,
.box_tab_detail_vouvher__content .rdt_TableHeadRow {
	background-color: #e9f4ee !important;
}
/* .box_new_voucher .rdt_TableBody {
	height: fit-content;
	max-height: 250px;
	overflow: auto;
} */
.box_new_voucher .rdt_TableRow,
.box_tab_detail_vouvher__content .rdt_TableRow {
	min-height: 25px !important;
}
.box_new_voucher .rdt_TableRow:nth-child(even),
.box_tab_detail_vouvher__content .rdt_TableRow:nth-child(even) {
	background-color: #f9f9f9 !important;
}
.box_new_voucher .rdt_TableRow:nth-child(odd),
.box_tab_detail_vouvher__content .rdt_TableRow:nth-child(odd) {
	background-color: #fff !important;
}
.box_new_voucher .rdt_TableRow:hover,
.box_tab_detail_vouvher__content .rdt_TableRow:hover {
	outline: none;
	background-color: #e9f4ee !important;
	border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}
.box_new_voucher .rdt_TableRow:focus-visible,
.box_tab_detail_vouvher__content .rdt_TableRow:focus-visible {
	outline: none;
	background-color: #e9f4ee !important;
}
.box_new_voucher .rdt_TableCell,
.box_tab_detail_vouvher__content .rdt_TableCell {
	border-right: 1px solid #ccc !important;
	white-space: normal !important;
	word-wrap: break-word;
}
.box_new_voucher .rdt_TableCell:last-child,
.box_tab_detail_vouvher__content .rdt_TableCell:last-child {
	border-right: none !important;
}
.box_new_voucher .rdt_TableCol,
.box_tab_detail_vouvher__content .rdt_TableCol {
	border-right: 1px solid #ccc !important;
	justify-content: center !important;
}

.box_voucher__body__content .rdt_TableBody .rdt_TableRow:first-child input {
	display: none;
}
.box_voucher__body__content .rdt_TableCol > input,
.box_voucher__body__content .rdt_TableCell > input,
.box_tab_detail_vouvher__content .rdt_TableCol > input,
.box_tab_detail_vouvher__content .rdt_TableCell > input {
	height: 13px;
	width: 13px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: 1px solid #999;
	outline: none;
	transition-duration: 0.3s;
	background-color: #fff;
	cursor: pointer;
}

.box_voucher__body__content .rdt_TableCol:first-child,
.box_voucher__body__content .rdt_TableCell:first-child,
.box_tab_detail_vouvher__content .rdt_TableCol:first-child,
.box_tab_detail_vouvher__content .rdt_TableCell:first-child {
	border: none;
	background-color: transparent;
}

.box_voucher__body__content .rdt_TableCol > input:checked,
.box_voucher__body__content .rdt_TableCell > input:checked,
.box_tab_detail_vouvher__content .rdt_TableCol > input:checked,
.box_tab_detail_vouvher__content .rdt_TableCell > input:checked {
	border: 1px solid #279656;
	background-color: #4caf50;
}

.box_voucher__body__content .rdt_TableCol > input:active,
.box_voucher__body__content .rdt_TableCell > input:active,
.box_tab_detail_vouvher__content .rdt_TableCol > input:active,
.box_tab_detail_vouvher__content .rdt_TableCell > input:active {
	border: 2px solid #279656;
}

.box_voucher__body__content .rdt_TableCol > input:checked:after,
.box_voucher__body__content .rdt_TableCell > input:checked:after,
.box_tab_detail_vouvher__content .rdt_TableCol > input:checked:after,
.box_tab_detail_vouvher__content .rdt_TableCell > input:checked:after {
	content: '';
	position: absolute;
	top: 49%;
	left: 50%;
	width: 3px;
	height: 6px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: translate(-50%, -50%) rotate(45deg);
}
.box_new_voucher .rdt_TableCol:last-child,
.box_tab_detail_vouvher__content .rdt_TableCol:last-child {
	border-right: none !important;
}
.box_voucher__body__content nav.rdt_Pagination {
	justify-content: start;
}

.box_voucher {
	width: 100%;
	height: 60vh;
}
@media screen and (max-width: 980px) {
	.box_voucher {
		height: fit-content;
	}
}
.box_voucher__header {
	/* background-color: #fff; */
	width: -webkit-fill-available;
	border-bottom: 1px solid #ccc;
}
.box_voucher__header__tab {
	background-color: #fff;
	width: -webkit-fill-available;
	padding: 5px;
	border-bottom: 1px solid #ccc;
}
.box_voucher__header__tab > * {
	display: flex;
	justify-content: center;
	align-items: end;
	padding: 0 10px;
	font-size: small;
	cursor: pointer;
	border-right: 1px solid #999;
}
.box_voucher__header__tab > *:hover {
	color: var(--secondary-color);
}
.box_voucher__header__tab > *:last-child {
	border-right: none;
}
.box_voucher__header__title {
	padding: 10px 15px;
}
.box_voucher__body {
	width: 100%;
}
.box_voucher__body > * {
	width: -webkit-fill-available;
}
.box_voucher__body__header {
	padding: 5px;
}
.box_voucher__body__header > * {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px 10px;
	font-size: medium;
	cursor: pointer;
	border: none !important;
	border-right: 1px solid #999 !important;
	background: none !important;
}
.box_voucher__body__header > *:hover {
	color: var(--secondary-color);
}
.box_voucher__body__header > *:focus {
	outline: none !important;
	color: var(--secondary-color) !important;
}
.box_voucher__body__header > *:last-child {
	border-right: none;
}
.box_voucher__body__header .tabname_tool_bar {
	margin-left: 3px;
	font-size: 13px;
}
@media screen and (max-width: 1024px) {
	.box_voucher__body__header .tabname_tool_bar {
		display: none;
	}
}

.dialog__content_edit_voucher {
	/* min-width: 1000px; */
}
.dialog__content_edit_voucher > * {
	margin-bottom: 10px;
}
.dialog__content_edit_voucher .field_input {
	width: 85%;
}
.dialog__content_edit_voucher .field_input_title {
	width: 150px;
	/* font-size: 16px;
}
.dialog__content_edit_voucher div.field-select-api__single-value,
div.field-select-api__placeholder,
div.field-select-api__input {
	font-size: 16px;
	color: #2e2e2e;
}
.dialog__content_edit_voucher .field_input_content_input {
	font-size: 16px;
	color: #2e2e2e; */
}
.dialog__content_edit_voucher__detail {
	width: 100%;
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
}
.dialog__content_edit_voucher__detail_left {
	width: 50%;
}
.dialog__content_edit_voucher__detail_right {
	width: 50%;
}
@media screen and (max-width: 1145px) {
	.dialog__content_edit_voucher__detail {
		flex-direction: column;
		width: 100%;
	}
	.dialog__content_edit_voucher__detail_left {
		width: 100%;
	}
	.dialog__content_edit_voucher__detail_right {
		width: 100%;
	}
}
.field_select_api_tabother {
	width: 100%;
}
.box_voucher_detail .tab_other .dialog__content_edit_voucher__detail_left {
	width: 30%;
	margin-right: 30px;
}
.box_voucher_detail .tab_other .dialog__content_edit_voucher__detail_right {
	width: 40%;
}
.box_voucher_detail .tab_other .dialog__content_edit_voucher__detail_right .field_select_api_tabother {
	width: calc(85% - 15px);
}

.dialog__content_edit_voucher__body {
	width: 100%;
}
.dialog__content_edit_voucher__body .tab__note {
	width: 100%;
	background-color: #f8f8f8;
	padding: 10px 16px;
	border-radius: 0 0 8px 8px;
	margin-bottom: 20px;
}
.dialog__content_edit_voucher__body .group_btn_note {
	width: 100%;
}
.box_date_picker_payment {
	width: calc(85% - 15px);
}
.box_date_picker_payment .react-datepicker-wrapper {
	width: auto;
	height: 38px;
}
.date_picker_payment {
	width: 100%;
	height: 100%;
	border: none;
	border-bottom: 1px solid #ccc;
	padding: 5px 10px 5px 0;
	margin: 10px auto;
	cursor: pointer;
	/* font-size: 16px; */
}

.date_picker_payment:focus-visible {
	outline: none;
}
.title_date_picker_payment {
	font-size: 13px;
	font-weight: 600;
	color: #2e2e2e;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: calc(100% / 3 + 5px);
}
.dialog__content_edit_voucher__body .MuiTabs-scroller span {
	background-color: var(--secondary-color) !important;
	height: 3px !important;
	border-radius: 6px 8px 0 0;
}
.dialog__content_edit_voucher__body .MuiTabs-scroller button span {
	background-color: transparent !important;
	height: none !important;
	border-radius: none;
}
.box_tab_detail_vouvher {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
}
.box_tab_detail_vouvher__header {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: start;
	align-items: center;
}
.box_tab_detail_vouvher__header > * {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	font-size: small;
	cursor: pointer;
	border: none !important;
	border-right: 1px solid #999 !important;
	background: none !important;
}
.box_tab_detail_vouvher__header > *:last-child {
	border-right: none;
}
.box_tab_detail_vouvher__header > *:hover {
	color: var(--secondary-color);
}
.box_tab_detail_vouvher__header > *:focus {
	outline: none !important;
	color: var(--secondary-color) !important;
}
.box_tab_detail_vouvher__content,
.box_tab_attachment_vouvher__content {
	max-height: 320px;
	overflow: auto;
	background: #f8f9fa;
}
.dialog__content_edit_voucher_body {
	overflow: auto !important;
	height: 80vh;
}

.dialog__content_edit_voucher__body_checkbox {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	/* font-size: 16px; */
}
.group_checkbox_voucher {
	width: 50%;
}
@media screen and (max-width: 1024px) {
	.group_checkbox_voucher {
		width: 40%;
	}
}

.item_price_voucher {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
	margin-left: 20px;
}
.item_price_voucher > * {
	margin-bottom: 10px;
}
.item_price_voucher span {
	min-width: 100px;
}

.box_voucher_detail {
	width: 100%;
	max-height: 25vh;
}
.box_voucher_detail .MuiTabs-scroller span {
	background-color: var(--secondary-color) !important;
	height: 3px !important;
	border-radius: 3px 8px 0 0;
}
.box_voucher_detail .MuiTabs-scroller button span {
	background-color: transparent !important;
	height: none !important;
	border-radius: none;
}

.box_detail_voucher_detail .tab_payment .field_input {
	width: 45%;
}

div.field-select-api-voucher__control {
	border: none;
	border-radius: 0;
	box-shadow: none;
	min-height: 23px !important;
	background-color: transparent !important;
	/* padding: 0px 10px; */
}

div.field-select-api-voucher__control:focus-within {
	outline: none;
	border-bottom: none;
}

div.field-select-api-voucher__value-container,
div.field-select-api-voucher__indicator {
	padding: 0;
	min-height: 23px !important;
	display: flex;
	align-items: flex-end;
}

div.field-select-api-voucher__single-value,
div.field-select-api-voucher__placeholder,
div.field-select-api-voucher__input {
	font-size: 13px;
	color: #67717e;
	margin: 0;
}
div.field-select-api-voucher__value-container div {
	height: 22px !important;
	position: absolute;
	left: 0;
}

span.field-select-api-voucher__indicator-separator {
	display: none;
}

div.field-select-api-voucher__option {
	font-size: 12px;
	color: #67717e;
}

div.field-select-api-voucher__multi-value {
	border-radius: 8px;
	overflow: hidden;
}

div.field-select-api-voucher__multi-value__label {
	font-size: 12px;
}

div.field-select-api-voucher__multi-value__remove:hover {
	cursor: pointer;
}

.btn_select_create {
	background-color: transparent;
	border: none;
	display: flex;
	color: #999999;
	cursor: pointer;
}
.btn_select_create:hover {
	color: #000000;
}

.total_price_voucher {
	padding: 10px;
	position: fixed;
	bottom: 0px;
	display: flex;
	right: 10px;
}

/* Filter search */
.filter_container {
	background-color: white;
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 300px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.filter_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.mdf_container {
	height: 100%;
	display: flex;
	gap: 16px;
}
.mdf_container .w_menu_modal {
	width: 200px;
}

@media screen and (max-width: 980px) {
	.mdf_container .w_menu_modal {
		width: 160px;
	}
}

.mdf_content {
	height: 100%;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
