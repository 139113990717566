* {
	margin: 0;
	padding: 0;
	/* box-sizing: border-box; */
}
.MuiTableHead-root {
	background-color: #dcf4fc;
	font-weight: bold;
	text-align: start;
}

/* tabInfo */
.table_detail {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
}
.table-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
}
.btn_header_table {
	background-color: #fff;
	color: var(--primary-color);
	border: none;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	margin-left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: rgb(0 0 0 / 5%) 0px 20px 27px 0px;
}
.btn_header_table:hover {
	opacity: 0.8;
}

.box_tabinfo {
	padding: 24px;
	border-bottom: 1px solid #ddd;
}
.item_detail {
	width: 50%;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
}
.line_item {
	background-color: #2e2e2e;
	width: 60%;
	margin-left: 0 !important;
}
.group_btn {
	display: flex;
	justify-content: right;
	align-items: center;
	width: 100%;
	padding: 10px 0;
}

/* ##################### */

.list_room {
	padding: 8px 0;
	width: 100%;
}
.list_room_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.title_name {
	font-size: 26px;
	margin: 0 20px 0 0;
	padding: 0;
	float: left;
	line-height: 34px;
	font-weight: 700;
	color: #333;
}
.group_btn_header {
	display: flex;
	justify-content: flex-end;
}
@media all and (max-width: 1024px) {
	.item_title {
		display: none;
	}
	button {
		height: 35px;
	}
}

.list_room_content {
	margin-top: 20px;
}

/* ##################### */

.rdt_Table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid #ddd;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 5%) 0px 20px 27px 0px;
}
div.rdt_TableHeader {
	background: transparent;
	padding: 0;
	overflow: visible;
	flex: 0;
}
div.rdt_TableHeader div {
	font-weight: bold;
}
div.rdt_TableHeader + div {
	flex: 1;
}

.rdt_TableHeader > div:last-child {
	display: none;
}

.rdt_TableHeadRow {
	background-color: var(--secondary-color) !important;
	color: white !important;
}

nav.rdt_Pagination {
	background-color: transparent;
	padding: 0;
	color: black;
	border: none;
}

/* custom scrollbar */
/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c1c1c1;
	border-radius: 5px;
	border: 1px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #c1c1c1;
}

nav.rdt_Pagination select {
	outline: none;
}

div.rdt_TableRow .rdt_TableCell {
	transition: all 0.3s ease-in-out;
}

div.rdt_TableRow .rdt_TableCell .row-action {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;

	transition: all 0.3s ease-in-out;
	width: 0;
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
}

div.rdt_TableRow:hover .row-action {
	width: 100px;
}

.row-action button {
	padding: 4px;
	transition: all 0.3s ease-in-out;
	transform: scale(0.9);
	width: 28px;
	min-height: auto;
	z-index: 1;
}

.row-action button:hover {
	transform: scale(1);
	opacity: 1;
}

.row-action button svg {
	width: 100%;
	height: 100%;
}
