.pointer {
	cursor: pointer;
}

.task_dialog_detail .MuiDialogTitle-root {
	background-color: #fff;
	border-bottom: 1px solid #f0f1f3;
}
.task_dialog_detail .MuiDialogContent-root {
	background-color: #f9f9fa;
	padding: 0;
	overflow: hidden;
}

.task_dialog_detail .MuiDialog-paper {
	width: 100%;
	/* height: 88%; */
	max-width: unset;
	max-height: unset;
	overflow: unset;
	z-index: 1000;
}

.task_dialog_detail .MuiDialog-scrollPaper {
	margin: 3vh !important;
}

.task_dialog_detail .ant-checkbox-wrapper-checked span {
	color: #aaa;
	text-decoration: line-through;
}

.task_dialog_detail__arrow__left {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: -30px;
	transform: translate(-50%, -50%);
	font-size: 35px;
	color: #fff;
	cursor: pointer;
}
.task_dialog_detail__arrow__right {
	width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	right: -80px;
	transform: translate(-50%, -50%);
	font-size: 35px;
	color: #fff;
	cursor: pointer;
}

.task_dialog_detail__arrow__left:hover,
.task_dialog_detail__arrow__right:hover {
	color: #60b1fc;
}

.task_dialog_detail__title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.task_dialog_detail__title__left {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.task_dialog_detail__title__left .task_name {
	padding: 0 32px 0 8px;
	border-radius: 6px;
}
.task_dialog_detail__title__left .task_name input {
	border: none;
	outline: none;
	font-size: 20px;
	font-weight: 500;
	color: #000;
	background-color: transparent;
	width: 100%;
}

.task_dialog_detail__title__left .task_name:hover {
	background-color: #f8f8f9;
}

.task_dialog_detail__title__right {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.task_dialog_detail__title__right .stage_change {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.task_dialog_detail__title__right .stage_change .btn_taskType {
	position: relative;
	background-color: #f0f1f3;
	color: #212529;
	border: none;
	outline: none;
	padding: 8px;
	border-radius: 5px 0 0 5px;
	height: 32px;
	z-index: 2000;
	cursor: pointer;
}
.task_dialog_detail__title__right .stage_change .btn_taskType:after {
	position: absolute;
	top: 0;
	right: -12px;
	content: '';
	width: 0;
	height: 0;
	border-top: 16px solid transparent;
	border-left: 12px solid #f0f1f3;
	border-bottom: 16px solid transparent;
}

.task_dialog_detail__title__right .stage_change .btn_taskStage {
	position: relative;
	width: 160px;
	height: 32px;
	background-color: var(--color);
	color: #fff;
	border: none;
	outline: none;
	box-shadow: none !important;
	padding-left: 20px;
	border-radius: 0 5px 5px 0;
}
.task_dialog_detail__title__right .stage_change .btn_taskStage .MuiGrid-container {
	width: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	position: absolute;
	top: 12%;
	left: 15%;
}
.task_dialog_detail__title__right .stage_change .btn_taskStage div.field-select-api__control {
	background-color: transparent;
	color: #fff;
}
.task_dialog_detail__title__right .stage_change .btn_taskStage div.field-select-api__single-value {
	color: #fff;
	font-size: 14px;
	font-weight: 400 !important;
}

.task_dialog_detail__title__right .stage_change .ant-select-selector {
	height: 32px;
	/* background-color: rgb(142, 144, 147); */
	background-color: var(--color);
	color: #fff;
	border: none;
	outline: none;
	box-shadow: none !important;
	padding-left: 20px;
	border-radius: 0 5px 5px 0;
}

.task_dialog_detail__title__right .stage_change .ant-select-dropdown {
	text-align: left;
}

.task_dialog_detail__title__right .action_change {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.task_dialog_detail__title__right .action_change i {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 22px;
	height: 22px;
	font-size: 13px;
	padding: 5px;
	border-radius: 4px;
	background-color: #f0f1f3;
	cursor: pointer;
}
.task_dialog_detail__title__right i:hover {
	background-color: #dfe1e6;
}

.task_dialog_detail__content {
	position: relative;
	display: flex;
	flex-direction: row;
	height: 565px;
}

.task_dialog_detail__content__left {
	width: 65%;
	height: calc(100% - 10px);
	display: flex;
	flex-direction: column;
	padding: 10px 8px 10px 16px;
	overflow-x: hidden;
	overflow-y: auto;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.task_dialog_detail__content__left .box-content {
	padding: 8px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	margin-bottom: 20px;
}

.task_dialog_detail__content__left .ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-inline-start: 0px;
}

.task_dialog_detail__content__left .info {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.task_dialog_detail__content__left .info .field {
	position: relative;
	width: calc(20% - 18px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 5px 8px;
	margin-bottom: 8px;
	border-right: 1px solid #d5d7d9;
}
.task_dialog_detail__content__left .info .field:nth-child(5n) {
	border-right: 1px solid transparent;
}

.field .field_viewOnly {
	width: 100%;
	height: 42px;
	margin-top: -10px;

	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
}
.field .field_viewOnly p {
	font-size: 13px;
	font-weight: 600;
	color: #0000008a;
}
.field .field_viewOnly input[type='text'] {
	font-size: 13px;
	font-weight: 400;
	border: none;
	outline: none;
	box-shadow: none;
	color: #67717e;
	background-color: transparent;
}

.task_dialog_detail__content__left .desc textarea {
	width: calc(100% - 16px);
	padding: 0 8px;
	border: none;
	outline: none;
	resize: none;
}

.task_dialog_detail__content__left .subTasks {
	padding: 0 8px;
}

.task_dialog_detail__content__left .activity {
	padding: 0 8px;
}

.task_dialog_detail__content__right {
	width: calc(35% - 98px);
	height: calc(100% - 10px);
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px 8px 10px 10px;
}

.task_dialog_detail__content__right .main_content {
	padding: 8px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.task_dialog_detail__content__menu {
	position: absolute;
	right: 0;
	top: 0;

	height: calc(100% + 10px);
	background-color: #fff;
	box-shadow: -1px 0 4px #eeeeee;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-top: none;
	border-bottom: none;
	overflow-x: hidden;
	overflow-y: auto;
}

.task_dialog_detail__content__menu ul li {
	padding-left: 0px !important;
	padding-right: 0px !important;
	flex-direction: row-reverse;
	text-align: right;
	font-weight: 500;
}

.task_dialog_detail__content__menu ul li .ant-menu-item-icon {
	padding: 0 15px !important;
}

.task_dialog_detail__content__menu .ant-menu-item-selected {
	background-color: #60b1fc;
	color: #fff;
}

.task_dialog_detail__content__left::-webkit-scrollbar-track,
.task_dialog_detail__content__left::-webkit-scrollbar-thumb,
.task_dialog_detail__content__right::-webkit-scrollbar-track,
.task_dialog_detail__content__right::-webkit-scrollbar-thumb,
.task_dialog_detail__content__menu::-webkit-scrollbar-track,
.task_dialog_detail__content__menu::-webkit-scrollbar-thumb {
	background-color: transparent;
	border-color: transparent;
}

.task_dialog_detail__content__left:hover::-webkit-scrollbar-thumb,
.task_dialog_detail__content__right:hover::-webkit-scrollbar-thumb,
.task_dialog_detail__content__menu:hover::-webkit-scrollbar-thumb {
	background-color: #c4c4c4;
	border-color: transparent;
}

.task_dialog_detail .container_add_subtask {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;
}
.task_dialog_detail .container_add_subtask input {
	width: 100%;
	padding: 2px 8px 2px 0;
	border: none;
	outline: none;
	background-color: transparent;
}
.task_dialog_detail .container_add_subtask i {
	cursor: pointer;
}

/* Override MUI Grid */
.task_dialog_detail .MuiGrid-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.task_dialog_detail .MuiGrid-grid-xs-4 {
	width: 100%;
	max-width: 100%;
	padding: 0 !important;
	color: #0000008a;
}

.task_dialog_detail .MuiGrid-grid-xs-true {
	width: 100%;
	max-width: 100%;
	padding: 0 !important;
}
/* Override MUI Grid */

/* CSS DATETIME DETAIL */
.dateTimePickerDetail {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.dateTimePickerDetail label {
	font-size: 13px;
	font-weight: bold;
}
.dateTimePickerDetail label::after {
	content: ' *';
	color: #ed5c6a;
}

.dateTimePickerDetail .fieldDateTime input {
	color: #67717e;
	font-size: 13px;
	padding: 10px 0px 5px 0px;
	width: auto;
}
/* CSS DATETIME DETAIL */

/* Override SelectAPI */
.task_dialog_detail div.field-select-api__control {
	border-bottom: none !important;
	min-height: 20px !important;
}
.task_dialog_detail .MuiInput-underline:before {
	border-bottom: none !important;
}
/* Override SelectAPI */
