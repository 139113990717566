.file-item {
	padding: 8px;
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
	position: relative;
}

.file-item:hover {
	background-color: var(--secondary-color);
}

.file-action {
	position: absolute;
	top: 8px;
	right: 8px;
	color: #fff;
	transition: all 0.3s ease-in-out;
	opacity: 0;
	display: flex;
	gap: 8px;
}

.file-item:hover .file-action {
	opacity: 1;
}

.file-name {
	color: #000;
	font-size: 14px;
	font-weight: bold;
	text-decoration: none;
}

.file-upload-info {
	color: #666;
	font-size: 12px;
	text-decoration: none;
}

.empty-file {
	font-size: 14px;
	font-weight: 600;
	color: #ccc;
}
