.btn-blue {
  background-color: #60b1fc;
}
.btn-white {
  background-color: #fff;
}
.btn-add {
  background-color: #60b1fc;
  border-radius: 6px 0 0 6px;
}
.btn-import {
  width: 30px;
  background-color: #60b1fc;
  border: none;
  border-left: 2px solid #49a4fa;
  border-radius: 0 6px 6px 0;
  outline: none;
  cursor: pointer;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action {
  height: auto;
  width: 100%;
  background-color: transparent;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  color: #000;
}
.btn-follow {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #595d5d;
}
.btn-follow:hover {
  color: #ff8e4b;
}
.btn-unfollow {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #ff8e4b;
}

.me-1 {
  margin-right: 0.5rem;
}

.ms-1 {
  margin-left: 0.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.box_lead {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.box_lead .box_lead__tool {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
}

.box_lead__table {
  position: relative;
}

.lead__modal_select {
  position: absolute;
  top: -15%;
  left: -10%;

  width: 120vw;
  height: 90vh;
  background-color: transparent;
}

.lead__modal_select__content {
  position: absolute;
  top: 50px;
  left: 65px;
  width: fit-content;
  background-color: #eee;
  padding: 20px 80px 20px 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.lead__modal_select__content .MuiFormControlLabel-label {
  font-size: 14px;
}

.lead__modal_select__content .MuiCheckbox-colorSecondary.Mui-checked {
  color: #60b1fc;
}

/* Set up table */

.box_lead .rdt_Table {
  background-color: transparent;
  border-radius: 0;
  border-top: none !important;
}

.box_lead .rdt_TableHeadRow {
  background-color: #e9f4ee !important;
  border-top: 0.5px solid #ccc;
}

.box_lead__table span.ant-dropdown-trigger.btn-action.ant-dropdown-open {
  color: #60b1fc;
}
/* .box_lead .rdt_TableHead {
  width: calc(100% - 8px);
  border-right: 1px solid #ccc;
} */
/* .box_lead .rdt_TableBody {
  height: fit-content;
  max-height: 250px;
  overflow: auto;
} */
.box_lead .rdt_TableRow {
  min-height: 25px !important;
}
.box_lead .rdt_TableRow:nth-child(even) {
  background-color: #f9f9f9 !important;
}
.box_lead .rdt_TableRow:nth-child(odd) {
  background-color: #fff !important;
}
.box_lead .rdt_TableRow:hover {
  outline: none;
  background-color: #e9f4ee !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}
.box_lead .rdt_TableRow:focus-visible {
  outline: none;
  background-color: #e9f4ee !important;
}
.box_lead .rdt_TableCell {
  border-right: 1px solid #ccc !important;
  white-space: normal !important;
  word-wrap: break-word;
}
.box_lead .rdt_TableCell:last-child {
  border-right: none !important;
}
.box_lead .rdt_TableCol {
  border-right: 1px solid #ccc !important;
  justify-content: center !important;
}

/* .box_lead .rdt_TableBody .rdt_TableRow:first-child input {
  display: none;
} */
.box_lead .rdt_TableCol > input,
.box_lead .rdt_TableCell > input {
  height: 15px;
  width: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #999;
  border-radius: 2px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.box_lead .rdt_TableCol:first-child,
.box_lead .rdt_TableCell:first-child {
  border: none;
  background-color: transparent;
}

.box_lead .rdt_TableCol > input:checked,
.box_lead .rdt_TableCell > input:checked {
  border: 1px solid #60b1fc;
  background-color: #60b1fc;
}

.box_lead .rdt_TableCol > input:active,
.box_lead .rdt_TableCell > input:active {
  border: 2px solid #60b1fc;
}

.box_lead .rdt_TableCol > input:checked:after,
.box_lead .rdt_TableCell > input:checked:after {
  content: "";
  position: absolute;
  top: 42%;
  left: 50%;
  width: 4px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.box_lead .rdt_TableCol:last-child {
  border-right: none !important;
}
.box_lead nav.rdt_Pagination {
  justify-content: start;
}
