#membership .header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding-top: 20px;
  width: 100%;
  padding-bottom: 10px;
}
#membership .header .title {
  font-size: 14px;
  display: flex;
  align-items: center;
}
#membership .header .tool {
  display: flex;
}
#membership .header .tool button {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0;
  outline: none;
  color: #fff;
  height: 30px;
  border-radius: 4px;
  padding-right: 10px;
  font-size: 14px;
}
#membership .header .tool button .icon {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 10px;
}
#membership .header .tool .btn_save {
  margin-left: 10px;
}
#membership .header .tool .btn_update .icon {
  background-color: rgb(111, 13, 2);
  height: 100%;
}
#membership .header .tool .btn_update {
  background-color: rgb(162, 78, 68);
}
#membership .header .tool .btn_save .icon {
  height: 100%;
  background-color: rgb(23, 23, 226);
}
#membership .header .tool .btn_save {
  background-color: rgb(110, 110, 185);
}
#membership .content {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 10px 8px;
}
#membership .content_row {
  background-color: #fff;
}
#membership .content h5 {
  padding: 12px 6px;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#membership .content h5,
#membership .content h6 {
  color: rgba(0, 0, 0, 0.8);
}
#membership .apply {
  padding-top: 15px;
  padding-left: 8px;
}
#membership .exchange_title {
  padding-top: 15px;
  padding-left: 8px;
}
#membership .exchange-input {
  padding-top: 15px;
  padding-left: 8px;
  display: flex;
  align-items: center;
}
#membership .exchange-right {
  margin-top: 6px;
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
}
#membership .content .icon {
  margin-left: auto;
  margin-right: auto;
}
#membership .content .input_left {
  margin-right: auto;
}
#membership .content .input_right {
  margin-left: auto;
}
#membership .content input[type="text"] {
  text-align: right;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
#membership .ant-input-group .ant-input-group-addon {
  padding-left: 7px;
  padding-right: 7px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
#membership .content .exchange-right i {
  font-size: 14px;
  margin-right: 6px;
}
#membership .content .exchange-right p {
  display: flex;
  align-items: center;
}
#membership .content .exchange-right .icon p i {
  font-size: 10px;
}
#membership .link_add {
  margin-top: 10px;
  padding-left: 8px;
}
#membership .checkmark {
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 15px;
  margin-left: 6px;
}
#membership .choose {
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 15px;
  margin-left: 6px;
  margin-bottom: 30px;
}
#membership .content h6,
#membership .content p,
#membership .content .link_add span,
#membership .content input[type="text"] {
  font-size: 12px;
}
#membership .content .link_add span {
  color: #3b9cf7;
}
#membership .content .link_add {
  display: flex;
  cursor: pointer;
  align-items: center;
}
#membership .content p,
#membership .content input[type="text"] {
  opacity: 0.8;
  font-weight: normal;
}
#membership .content p.exchange_title {
  font-weight: 500;
}
#membership .content label {
  font-size: 12px;
  display: flex;
  align-items: center;
  opacity: 0.8;
}
#membership .content .checkmark label {
  justify-content: space-between;
  margin-top: 10px;
}
#membership .content .apply label span,
#membership .content .choose label span {
  margin-left: 6px;
}
#membership .content .apply label:nth-child(2) {
  margin-left: 20px;
}
#membership .content .choose label {
  margin-top: 10px;
}
#membership .content label input {
  font-size: 12px;
}
/* input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  appearance: none;
  background: white;
  border-radius: 1px;
  outline: 1px solid #333;
  border: none;
  width: 12px;
  height: 12px;
  position: relative;
} */
label {
  position: relative;
}
input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background: #333;
}
.apply label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.checkmark label::after {
  content: "";
  position: absolute;
  right: 4px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
.choose label {
  padding-left: 15px;
}
.choose label::after {
  content: "";
  position: absolute;
  left: 19px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}
