.header_left_main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 0 10px 0;
}
.group_button {
	display: flex;
	align-items: center;
	margin: 0 0 10px 0;
}
.group_button > button {
	margin: 0 5px;
}
.group_filter {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 0 10px 0;
}

.contain_left_main {
	width: 100%;
	height: calc(100% - 100px);
	padding: 0 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: auto;
	align-content: flex-start;
}
.item_left_main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: calc(100% / 2 - 10px);
	height: fit-content;
}
@media screen and (max-width: 1240px) {
	.item_left_main {
		width: calc(100% - 10px);
	}
}
.item_spa {
	display: flex;
	align-items: start;
	width: 100%;
	margin: 0 0 10px 0;
	padding: 10px;
	border-radius: 12px;
	cursor: pointer;
}
.item_spa > * {
	margin: 0 5px;
}
.item_spa:hover {
	background: rgba(102, 102, 102, 0.1);
	color: #279656;
}
.tooltip_item_spa_name {
	display: flex;
	margin-left: 5px;
	opacity: 0;
}
.tooltip_item_spa_name button {
	border: none;
	outline: none;
	color: #999999;
}
.item_spa:hover .tooltip_item_spa_name {
	opacity: 1;
}
.tooltip_item_spa_name:hover button {
	color: #279656;
}
.box_image_total_product {
	position: relative;
}
.total_product {
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	margin: 10px 0;
	background: #e9f4ee;
	border-radius: 16px;
	border: 1px solid #279656;
	width: 80%;
}
.total_product button {
	border: none;
	outline: none;
	color: #279656;
	width: 100%;
	background: transparent;
}
.item_spa:hover .total_product {
	opacity: 1;
}
.box_image {
	border-radius: 8px;
	width: 72px;
	height: 72px;
	object-fit: cover;
}
.item_spa_box_info {
	display: flex;
	align-items: start;
	flex-direction: column;
}

.box_right_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: calc(100% - 34px);
}
.header_right_main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 5px 0 10px 0;
}

.header_right_main_input {
	display: flex;
	align-items: center;
	margin: 0 0 10px 0;
}
.header_right_main_input > * {
	margin: 0 5px;
}
.header_right_main_select {
	display: flex;
	align-items: center;
	justify-content: end;
	margin: 0 0 10px 0;
}
.header_right_main_select > * {
	margin: 0 5px;
}
.form-control {
	border: 1px solid transparent;
	background: transparent;
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 4px;
	background: white;
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
		0 1px 5px 0 rgb(0 0 0 / 12%);
	min-height: 30px;
}
.form-control:hover {
	background: #f2f2f2;
}
.form-control:focus-visible {
	outline: none;
	border: 1px solid #4caf50;
}
select.form-control option {
	font-size: 16px;
	color: #333;
	background: white;
	padding: 10px;
	margin: 10px;
}
select.form-control option:hover {
	background: #f2f2f2;
}
select.form-control option:checked {
	background: #ddd;
}

.box_item_bill {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0 0 10px 0;
	border: 1px solid #ccc;
	box-shadow: 0px 0px 12px rgb(0 0 0 / 0%);
	border-radius: 5px;
	padding: 12px 16px;
	background-color: white;
}
.box_item_bill:hover {
	border-color: #279656;
	box-shadow: 0px 0px 12px rgb(39 150 86 / 40%);
}
.pt-3 {
	padding-top: 3px;
}
.ptb-0 {
	padding-top: 0;
	padding-bottom: 0;
}
.mb-10 {
	margin-bottom: 10px;
}
.header_right_main_item_bill {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	margin: 0 0 10px 0;
	height: calc(100% - 63px);
	overflow: auto;
}
.item_bill {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
}
.detail_info_bill {
	display: flex;
	align-items: flex-start;
	justify-content: start;
}
.detail_info_bill > * {
	margin: 0 5px;
}
.detail_price_bill {
	display: flex;
	align-items: flex-start;
	justify-content: end;
}
.detail_price_bill > * {
	margin: 0 15px;
}
.gr_quantity {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	cursor: pointer;
}
.gr_quantity > * {
	margin: 0 5px;
}
.item_bill:hover .gr_quantity button {
	opacity: 1;
}
.gr_quantity > button {
	opacity: 0;
	border: none;
	background: transparent;
	cursor: pointer;
	border-radius: 50%;
	font-size: medium;
	display: flex;
	justify-content: center;
}
.gr_quantity > button:hover {
	background: #f2f2f2;
}
.gr_quantity > button:focus-visible {
	outline: none;
}
.gr_quantity > input {
	border: none;
	background: transparent;
	text-align: center;
	width: 35px;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
}
.gr_quantity:hover input {
	background-color: #f2f2f2;
	border-bottom: 1px solid #279656;
}
.gr_quantity > input:focus-visible {
	outline: none;
}
.gr_quantity > input::-webkit-outer-spin-button,
.gr_quantity > input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.item_bill__name {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 100%;
}
.bill_note {
	color: #999999;
	font-size: small;
}
.item_bill__price {
	display: flex;
	flex-direction: column;
	align-items: end;
	justify-content: end;
}
.price {
	text-decoration: underline;
	padding-bottom: 1px;
	text-decoration-color: #ccc;
	cursor: pointer;
}
.price:hover {
	text-decoration-color: #279656;
}
.price_sell {
	font-size: small;
	color: red;
}
.add_time_item_bill {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	padding-left: 20px;
}
.add_time_item_bill > * {
	margin: 0 5px;
}
.add_time_item_bill > .btn_add {
	color: #279656;
	border: none;
	background: transparent;
	cursor: pointer;
	font-size: small;
	display: flex;
	justify-content: center;
}
.add_time_item_bill > .btn_add:hover span {
	text-decoration: underline;
}
.group_add_time {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	margin: 0 0 10px 0;
}
.group_add_time > * {
	margin: 0 5px;
}
.group_clock {
	display: flex;
	align-items: center;
	justify-content: start;
}
.group_clock > * {
	margin: 0 5px;
}
.box_clock {
	display: flex;
	align-items: center;
	justify-content: start;
	width: fit-content;
	box-shadow: 0px 0px 12px rgb(0 0 0 / 0%);
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	padding: 6px;
}
.box_clock:hover {
	border: 1px solid #279656;
	box-shadow: 0px 0px 12px rgb(39 150 86 / 40%);
}
.box_clock > * {
	margin: 0 5px;
	border-bottom: transparent !important;
}
/* .MuiSelect-select.MuiSelect-select {
	padding: 0 5px 0 !important;
	font-size: smaller;
}
.add_time_item_bill .group_clock .MuiInput-underline:before {
	border-bottom: none !important;
}
.add_time_item_bill .group_clock .MuiInput-underline:after {
	border-bottom: none !important;
}
.add_time_item_bill
	.group_clock
	.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}
.add_time_item_bill .group_clock .MuiSelect-select:focus {
	background-color: transparent !important;
}
.add_time_item_bill .group_clock .css-1xc3v61-indicatorContainer {
	padding: 4px 8px !important;
} */
.add_time_item_bill .MuiSelect-select.MuiSelect-select {
	padding: 0 5px 0 !important;
	font-size: smaller;
}
.add_time_item_bill .MuiInput-underline:before {
	border-bottom: none !important;
}
.add_time_item_bill .MuiInput-underline:after {
	border-bottom: none !important;
}
.add_time_item_bill .MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}
.add_time_item_bill .MuiSelect-select:focus {
	background-color: transparent !important;
}
.add_time_item_bill .css-1xc3v61-indicatorContainer {
	padding: 4px 8px !important;
}

.header_right_main_item_pay {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 100%;
}

.box_filter_drawers {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}
.drawer_left_main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
	height: 100%;
}
.drawer_left_main_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 50px;
	border-bottom: 1px dashed #c4c4c4;
	margin-bottom: 12px;
}
.drawer_left_main_header span {
	font-size: large;
	font-weight: 600;
}
.drawer_left_group_btn {
	display: flex;
	align-items: center;
	justify-content: end;
	width: 100%;
	min-height: 50px;
}
.drawer_left_group_btn > * {
	margin: 0 5px;
}
.drawer_left_group_btn > button {
	min-width: 80px;
}
.drawer_left_main_contain {
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	height: 100%;
}
.drawer_left_main_contain > * {
	margin: 0 0 10px 0;
}
.drawer_left_main_contain label > .MuiCheckbox-colorPrimary.Mui-checked + span {
	font-weight: bold;
}
.drawer_left_main_contain_search {
	margin-bottom: 20px;
}

.detail_service {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	padding-left: 30px;
	margin-top: 15px;
}

.box_bill_drawers {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}
.box_bill_drawers_content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}
.box_bill_drawers_content_left {
	width: 60%;
	height: 100%;
	padding-right: 10px;
}
.box_bill_drawers_content_right {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 40%;
	height: 100%;
	padding-left: 10px;
	border-left: 1px dashed #c4c4c4;
}
.box_bill_drawers_content_left_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 10px 20px;
	background-color: #e9f4ee;
	color: #279656;
	border-radius: 12px;
	font-size: small;
}
.box_bill_drawers_content_left_header span {
	font-weight: 500;
}
.font-b {
	font-weight: 500;
}
.w-50 {
	width: 50%;
}
.box_bill_drawers_content_left_item {
	display: flex;
	align-items: start;
	justify-content: space-between;
	width: 100%;
	padding: 10px 20px 0;
	font-size: small;
}
.box_bill_drawers_content_left_item div {
	display: flex;
	align-items: start;
	justify-content: start;
}
.box_bill_drawers_content_left_item div > * {
	margin: 0 5px;
}
.box_note_bill {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}
.box_price_bill {
	display: flex;
	flex-direction: column;
	align-items: end;
	justify-content: start;
}
.box_note_bill > * {
	margin: 0 0 3px 0;
}
.box_note_bill > * {
	margin: 0 0 3px 0;
}
.price_sale {
	color: #bf4a56;
	font-size: smaller;
	text-decoration: line-through;
}
.note_bill {
	font-size: smaller;
	color: #999999;
}
.box_note_bill_detail {
	font-size: smaller;
	color: #279656;
}
.box_bill_drawers_content_right_detail {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
}
.right_detail_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.right_detail_header > * {
	width: calc(50% - 10px);
}
.group_date_time_bill {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 6px;
	background-color: #e9f4ee;
	padding: 9px 10px;
}
.right_detail_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
}
.right_detail_content__item_price {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	width: 100%;
}
.right_detail_content__item_price > * {
	margin: 20px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.right_detail_content__item_price .total {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	background-color: #999999;
	color: #fff;
	font-size: small;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
}
.d-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.right_detail_content__group_radiobox {
	display: flex;
	align-items: center;
	justify-content: start;
	width: 100%;
	margin: 20px 0;
}
.custom-group-radio {
	align-items: center;
	justify-content: space-around;
	width: 80%;
	padding-left: 20px !important;
}
.custom-group-radio > * {
	margin: 0 10px 0 0;
}
.custom-radio {
	width: 20px;
	height: 20px;
	color: #279656 !important;
	margin-right: 5px !important;
}
.right_detail_content__money {
	display: flex;
	align-items: center;
	justify-content: start;
	background-color: #e9f4ee;
	border-radius: 12px;
	padding: 10px 20px;
	width: 100%;
}
.right_detail_content__money > * {
	margin: 0 10px 0 0;
}
.right_detail_content__money button {
	border-radius: 20px;
	padding: 5px 10px;
	min-height: 25px;
}
.right_detail_content__money_change {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
}
.text-green {
	color: #279656;
}
.box_bill_drawers_content_right_btn_note {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: end;
	width: 100%;
}
.box_bill_drawers_content_right_btn_note > * {
	margin: 0 0 10px 0;
}
.box_bill_drawers_content_right_btn_note button {
	width: 80%;
	padding: 10px 0;
	text-transform: uppercase;
	font-size: 14px;
	margin: 0 auto 10px;
}

.box_bill_drawers_content_right_btn_note .MuiInputBase-root input {
	padding: 4px;
}
.box_bill_drawers_content_right_btn_note .MuiInputBase-root {
	padding-left: 0;
	padding: 4px;
}

.dialog_add_time {
	min-height: 450px !important;
}
.box_dialog_add-time {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 100%;
	border-top: 1px dashed #999999;
	padding: 20px 0;
}
.box_dialog_add-time > * {
	margin: 0 0 20px 0;
}
.custom-input_calendar {
	border: none;
	border-bottom: 1px solid #999999;
	padding: 5px 0;
	background: transparent;
}
.date_picker-custom .react-datepicker__triangle {
	display: none !important;
}
.custom-input_calendar:focus-within {
	border: none;
	border-bottom: 1px solid #279656;
}
.custom-input_calendar:hover {
	border: none;
	border-bottom: 1px solid #279656;
}

.box_dialog_spa_sales__package {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 1000px;
	border-top: 1px dashed #999999;
	padding: 20px 0;
}
.box_dialog_spa_sales__package > * {
	margin: 0 0 15px 0;
	width: 100%;
}
.spa_sales__package_item {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	margin: 10px 0;
}
.spa_sales__package_item__name {
	width: 100%;
	background-color: #279656;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border-radius: 10px;
	padding: 10px;
}
.spa_sales__package_item__detail {
	padding-top: 20px;
	width: 100%;
}
.spa_sales__package_item__detail > * {
	padding: 0 10px;
	margin: 0 0 20px 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
}
.spa_sales__package_item__detail__item > * {
	margin: 0 10px 0 0;
}

.spa_sales__package_item__detail__item:first-child {
	margin-right: 20px;
}
