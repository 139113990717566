.quickEdit {
  position: absolute;
  bottom: -10px;
  left: 0;
  transform: translateY(100%);

  width: 350px;
  background-color: #fefefe;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  z-index: 2000;
  overflow: hidden;
}

.quickEdit__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 13px;
  font-weight: 600;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  padding: 8px 10px;
  color: #000000de;
}
.quickEdit__title i {
  cursor: pointer;
}

.quickEdit__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 10px;
}

.quickEdit__content input {
  padding: 6px 12px;
  color: #1c2121;
  border: 1px solid#dfe1e6;
  border-radius: 5px;
  outline: none;
  width: calc(100% - 26px);
  margin-bottom: 8px;
}
.quickEdit__content input:focus {
  border: 1px solid#237afe !important;
  box-shadow: 0 0 0 0.08rem rgba(0, 123, 255, 0.8) !important;
}

.quickEdit__content button {
  padding: 6px 18px;
  background-color: #60b1fc;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 13px;
}
