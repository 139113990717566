.box_task__table .box_table_manager table tbody tr td {
	height: 32px !important;
	display: flex !important;
	align-items: center;
}

/* Set up table */
/* .box_task__table .rdt_Table {
	background-color: transparent;
	border-radius: 0;
	border-top: none !important;
}
.box_task__table .rdt_TableHeadRow {
	border-top: 0.5px solid #ccc;
}

.box_task__table .rdt_TableRow {
	min-height: 25px !important;
}
.box_task__table .rdt_TableRow:nth-child(even) {
	background-color: #f9f9f9 !important;
}
.box_task__table .rdt_TableRow:nth-child(odd) {
	background-color: #fff !important;
}
.box_task__table .rdt_TableRow:hover {
	outline: none;
	background-color: #e9f4ee !important;
	border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}
.box_task__table .rdt_TableRow:focus-visible {
	outline: none;
	background-color: #e9f4ee !important;
}
.box_task__table .rdt_TableCell {
	border-right: 1px solid #ccc !important;
	white-space: normal !important;
	word-wrap: break-word;
}
.box_task__table .rdt_TableCell:last-child {
	border-right: none !important;
}
.box_task__table .rdt_TableCol {
	border-right: 1px solid #ccc !important;
	justify-content: center !important;
}

.box_task__table .rdt_TableCol > input,
.box_task__table .rdt_TableCell > input {
	height: 15px;
	width: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	border: 1px solid #999;
	border-radius: 2px;
	outline: none;
	transition-duration: 0.3s;
	background-color: #fff;
	cursor: pointer;
}

.box_task__table .rdt_TableCol:first-child,
.box_task__table .rdt_TableCell:first-child {
	border: none;
	background-color: transparent;
}

.box_task__table .rdt_TableCol > input:checked,
.box_task__table .rdt_TableCell > input:checked {
	border: 1px solid #60b1fc;
	background-color: #60b1fc;
}

.box_task__table .rdt_TableCol > input:active,
.box_task__table .rdt_TableCell > input:active {
	border: 2px solid #60b1fc;
}

.box_task__table .rdt_TableCol > input:checked:after,
.box_task__table .rdt_TableCell > input:checked:after {
	content: '';
	position: absolute;
	top: 42%;
	left: 50%;
	width: 4px;
	height: 7px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: translate(-50%, -50%) rotate(45deg);
}
.box_task__table .rdt_TableCol:last-child {
	border-right: none !important;
}
.box_task__table nav.rdt_Pagination {
	justify-content: start;
} */

.box_task__table span.ant-dropdown-trigger.btn-action.ant-dropdown-open {
	color: #60b1fc;
	font-weight: bold;
}
