.filter_report .shadow__box {
	box-shadow: none !important;
	padding: 12px 0 !important;
	margin: 0 !important;
}
.form_filter_report {
	overflow-y: auto;
	max-height: 675px !important;
}
.date_filter_report {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 70%;
}
.date_filter_report .date_picker_payment {
	padding: 0 10px 0 0 !important;
	border: none !important;
}

.form_filter_report div.field-select-api__control {
	border-bottom: none !important;
}
.form_filter_report div.field-select-api__control .field_input_title {
	font-size: 14px !important;
	font-weight: 600 !important;
	color: 0000 !important;
}
.form_filter_report .MuiGrid-spacing-xs-2 {
	width: calc(100% + 6px) !important;
}
