.box_workshift {
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	margin: 10px auto;
	box-shadow: 0 0 5px #ccc;
}
.box_workshift__title {
	font-size: 20px;
	font-weight: bold;
	text-align: left;
	border-bottom: 1px solid gray;
	margin: 10px;
	padding: 10px 0 20px;
}
.box_workshift__content {
	margin: 30px 10px 10px;
}
.box_workshift__content__action {
	margin: 20px 0;
}
.box_workshift__content__action__filter {
	padding: 15px 30px;
}
.box_workshift__content__action__filter > * {
	margin-right: 20px;
}
.date_picker {
	width: 100%;
	height: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	margin: 10px auto;
	box-shadow: 0 0 5px #ccc;
	cursor: pointer;
}
.date_picker:focus-visible {
	outline: none;
	box-shadow: 0 0 5px #cefad0;
}
.box_workshift__content__action__setting {
	padding: 15px 30px;
}
.box_workshift__content__table {
	margin: 30px;
}
.ml-0 {
	margin-left: 0;
}
