.mdf_container {
	height: 100%;

	display: flex;
	gap: 16px;
}

.mdf_content {
	height: 100%;
	flex: 1;
	overflow: auto;

	display: flex;
	flex-direction: column;
	gap: 16px;
}
