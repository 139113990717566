.loaderRectangle {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0 4px;
}

.loaderRectangle div {
	width: 8px;
	height: 30%;
	animation: 1s ease-in-out infinite;
	background: var(--primary-color);
	box-shadow: 0 0 12px var(--secondary-color);
	border-radius: 8px;
}

.loaderRectangle div:nth-child(1) {
	animation-name: rectangleOneAnim;
}

@keyframes rectangleOneAnim {
	0% {
		height: 30%;
	}

	50% {
		height: 60%;
	}

	100% {
		height: 30%;
	}
}

.loaderRectangle div:nth-child(2) {
	animation-name: rectangleTwoAnim;
	animation-delay: 0.1s;
}

@keyframes rectangleTwoAnim {
	0% {
		height: 30%;
	}

	50% {
		height: 80%;
	}

	100% {
		height: 30%;
	}
}

.loaderRectangle div:nth-child(3) {
	animation-name: rectangleThreeAnim;
	animation-delay: 0.2s;
}

@keyframes rectangleThreeAnim {
	0% {
		height: 30%;
	}

	50% {
		height: 100%;
	}

	100% {
		height: 30%;
	}
}

.loaderRectangle div:nth-child(4) {
	animation-name: rectangleTwoAnim;
	animation-delay: 0.3s;
}

.loaderRectangle div:nth-child(5) {
	animation-name: rectangleOneAnim;
	animation-delay: 0.4s;
}
