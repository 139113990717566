.toast {
	z-index: 2000 !important;
}

.btn-blue {
	background-color: var(--primary-color);
}

.btn-white {
	background-color: #fff;
}

/* .btn-filter span.ant-select-selection-item:before {
  font-family: "Font Awesome 5 Free";
  content: "\f0b0";
  padding-right: 5px;
} */

.btn-add {
	background-color: var(--primary-color);
	border-radius: 6px 0 0 6px;
}

.btn-import {
	width: 30px;
	background-color: var(--primary-color);
	border: none;
	border-left: 2px solid #1e657d;
	border-radius: 0 6px 6px 0;
	outline: none;
	cursor: pointer;
	color: #fff;

	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-action {
	height: auto;
	width: 100%;
	background-color: transparent;
	text-align: center;
	border: none;
	outline: none;
	cursor: pointer;
	color: #000;
}

.btn-follow {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	color: #595d5d;
}
.btn-follow:hover {
	color: #ff8e4b;
}
.btn-unfollow {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	color: #ff8e4b;
}

.me-1 {
	margin-right: 0.5rem;
}

.ms-1 {
	margin-left: 0.5rem;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.box_task {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.box_task .box_header {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;

	height: 45px;
	width: 100%;
}

.box_task .box_header .box_task__tool {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.box_task .box_header .box_task__tool .action_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 4px 5px;
	background-color: #fff;
	border-radius: 5px;
}
.box_task .box_header .box_task__tool .action_container i {
	font-size: 14px;
	padding: 5px;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 8px;
}
.box_task .box_header .box_task__tool .action_container i:hover {
	background-color: #ddd;
}

.box_task .box_header .box_task__tool .selected_container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 7px 40px;
	background-color: #fff;
	border-radius: 5px;
}
.box_task .box_header .box_task__tool .selected_container p {
	font-size: 14px;
	font-weight: 500;
	color: #000;
	padding: 0 40px;
}
.box_task .box_header .box_task__tool .selected_container span {
	font-size: 14px;
	font-weight: 400;
	color: #656464;
}

.box_task .box_header .box_task__tool .ant-radio-button-checked {
	background-color: transparent !important;
	border-color: var(--primary-color) !important;
}
.box_task .box_header .box_task__tool .ant-radio-button-wrapper-checked {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}
.box_task .box_header .box_task__tool .ant-radio-button-wrapper-checked::before {
	background-color: var(--primary-color) !important;
	border-color: var(--primary-color) !important;
}

.box_task__table {
	position: relative;
}

.task__modal_select {
	position: absolute;
	top: -15%;
	left: -10%;

	width: 120vw;
	height: 90vh;
	background-color: transparent;
}

.task__modal_select__content {
	position: absolute;
	top: 50px;
	left: 65px;
	width: fit-content;
	background-color: #eee;
	padding: 20px 80px 20px 20px;
	border-radius: 10px;
	box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.task__modal_select__content .MuiFormControlLabel-label {
	font-size: 14px;
}

.task__modal_select__content .MuiCheckbox-colorSecondary.Mui-checked {
	color: var(--primary-color);
}

/* CSS TASK DISLOG ADD */
.dateTimePicker {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: start;
	width: 85%;
	padding: 6px 0 0 0;
}

.dateTimePicker label {
	font-size: 13px;
	font-weight: bold;
}
.dateTimePicker label::after {
	content: ' *';
	color: #ed5c6a;
}

.dateTimePicker .fieldDateTime input {
	color: #67717e;
	font-size: 13px;
	padding-bottom: 12px;
	/* padding: 12px 0px 20px 0px; */
	padding: 16px 0;
}
/* CSS TASK DISLOG ADD */
