.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 16px;
}

.ic_followed,
.ic_not_followed {
	font-size: 20px;
	cursor: pointer;
}

.ic_followed {
	color: var(--warning-color);
}

.ic_not_followed {
	color: var(--gray-color);
}

.filter_container {
	background-color: white;
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	width: 300px;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.filter_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.filter_title span {
	font-weight: 500;
}

.filter_footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 16px;
}
