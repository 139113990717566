
/* icon dialog fieldselect */
.icon_add_field_select{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    padding: 4px;
}
.icon_add_field_select:hover{
    background-color: #ccc;
}