.dialog__content_edit_multi_body {
	overflow: unset !important;
}

.select_title {
	font-size: 14px;
	font-weight: 500;
	color: #000;
	margin-right: 10px;
}

.edit_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;

	width: 100%;
	margin-top: 20px;
	padding-top: 10px;
	border-top: 0.5px solid #ccc;
}

.edit_container .fieldEdit {
	width: 45% !important;
}

/* CSS DATETIME EDIT */
.dateTimePickerEdit {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	width: 100%;
	padding: 5px 0px;
}

.dateTimePickerEdit label {
	font-size: 13px;
	font-weight: bold;
}
.dateTimePickerEdit label::after {
	content: ' *';
	color: #ed5c6a;
}

.dateTimePickerEdit .fieldDateTime {
	padding-left: 5px;
}

.dateTimePickerEdit .fieldDateTime input {
	color: #67717e;
	font-size: 13px;
	padding-bottom: 12px;
	padding: 12px 0px 20px 0px;
}
/* CSS DATETIME EDIT */
