.v-modal-content {
	display: flex;
	flex-direction: column;
	gap: 16px;

	width: 100%;
	height: 100%;
	overflow: hidden;
}

.v-modal-header,
.v-modal-footer {
	margin: 0;
}

.v-modal-body {
	height: 100%;
	flex: 1;
	overflow: auto;
}

/* Voucher */
/* .v-modal-title span {
	font-size: 20px;
}
.ant-menu-title-content {
	font-size: 18px;
}
.ant-collapse-header-text {
	font-size: 18px;
}
.ant-collapse-expand-icon {
	align-items: end !important;
} */
