.headerContainer {
	margin: 16px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.headerTitle {
	font-size: 24px;
	font-weight: 600;
}

.headerTitle strong {
	color: var(--primary-color);
}

.tabsContainer {
	background: white;
	padding: 16px;
	padding-top: 0;
	margin-top: 16px;
	border-radius: 8px;
}

.logoImage {
	width: 100%;
	border: 1px dashed #ccc;
	border-radius: 12px;
	max-height: 200px;
}

.logoContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 8px;
}

.logoAction {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
}

.logoAction button {
	transition: all 0.3s ease-in-out;
	transform: scale(0.9);
}

.logoAction button:hover {
	transform: scale(1);
	opacity: 0.9;
}

.configTab,
.otherTab {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.fieldCheckbox {
	font-size: 13px;
	font-weight: bold;
	color: #2e2e2e;
	display: flex;
	justify-content: start;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
}

.copyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.copyContainer button {
	align-self: flex-end;
	margin-top: 8px;
	transition: all 0.3s ease-in-out;
}

.copyContainer button:hover {
	opacity: 0.9;
}

.emptyFileText {
	font-size: 14px;
	font-weight: bold;
	color: #ccc;
}

.fileName {
	font-size: 14px;
	font-weight: bold;
	color: #2e2e2e;
}

.fileSize {
	font-size: 12px;
}

.warningText {
	color: red;
	align-self: flex-end;
}

.copyContainer svg {
	max-height: 120px;
	width: fit-content;
}

.loadingContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
