.pointer {
  cursor: pointer;
}

.lead_dialog_edit .MuiDialogTitle-root {
  background-color: #fff;
  border-bottom: 1px solid #f0f1f3;
}
.lead_dialog_edit .MuiDialogContent-root {
  background-color: #f9f9fa;
  padding: 0;
  overflow: hidden;
}

.lead_dialog_edit .MuiDialog-paper {
  width: 100%;
  /* height: 88%; */
  max-width: unset;
  max-height: unset;
  overflow: unset;
  z-index: 1000;
}

.lead_dialog_edit .MuiDialog-scrollPaper {
  margin: 3vh !important;
}

.lead_dialog_edit__arrow__left {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #fff;
  cursor: pointer;
}

.lead_dialog_edit__arrow__right {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: -80px;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #fff;
  cursor: pointer;
}

.lead_dialog_edit__arrow__left:hover,
.lead_dialog_edit__arrow__right:hover {
  color: #60b1fc;
}

.lead_dialog_edit__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lead_dialog_edit__title__left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lead_dialog_edit__title__right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.lead_dialog_edit__title__right i {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  background-color: #f0f1f3;
  cursor: pointer;
}
.lead_dialog_edit__title__right i:hover {
  background-color: #dfe1e6;
}

.lead_dialog_edit__content {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 565px;
}

.lead_dialog_edit__content__left {
  width: 65%;
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  padding: 10px 8px 10px 16px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.lead_dialog_edit__content__left .box-content {
  padding: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}
.lead_dialog_edit__content__left .info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.lead_dialog_edit__content__left .info .field {
  position: relative;
  width: calc(20% - 22px);
  display: flex;
  flex-direction: column;

  padding: 4px 12px 4px 8px;
  margin-bottom: 8px;
  border-right: 1px solid #d5d7d9;
}
.lead_dialog_edit__content__left .info .field:nth-child(5n) {
  border-right: 1px solid transparent;
}

.lead_dialog_edit__content__left .info .field .title {
  font-size: 12px;
  color: #7b7f85;
}
.lead_dialog_edit__content__left .info .field .require:after {
  content: "*";
  font-size: 13px;
  color: #e55907;
}
.lead_dialog_edit__content__left .info .field .content {
  font-size: 13px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lead_dialog_edit__content__left .info .field .special {
  color: #0065ff !important;
}
.lead_dialog_edit__content__left .info .field .content_notset::after {
  content: "Not set";
  font-size: 13px;
  color: #c4c4c4;
}
.lead_dialog_edit__content__left .info .field .btnEdit {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;

  visibility: hidden;
}
.lead_dialog_edit__content__left .info .field:hover .btnEdit {
  visibility: visible;
}

.lead_dialog_edit__content__left .desc textarea {
  width: calc(100% - 16px);
  padding: 0 8px;
  border: none;
  outline: none;
  resize: none;
}

.lead_dialog_edit__content__right {
  width: calc(35% - 98px);
  height: calc(100% - 10px);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 8px 10px 10px;
}

.lead_dialog_edit__content__right .main_content {
  padding: 8px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.lead_dialog_edit__content__menu {
  position: absolute;
  right: 0;
  top: 0;

  height: calc(100% + 10px);
  background-color: #fff;
  box-shadow: -1px 0 4px #eeeeee;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  border-bottom: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.lead_dialog_edit__content__menu ul li {
  padding-left: 0px !important;
  padding-right: 0px !important;
  flex-direction: row-reverse;
  text-align: right;
  font-weight: 500;
}

.lead_dialog_edit__content__menu ul li .ant-menu-item-icon {
  padding: 0 15px !important;
}

.lead_dialog_edit__content__menu .ant-menu-item-selected {
  background-color: #60b1fc;
  color: #fff;
}

.lead_dialog_edit__content__left::-webkit-scrollbar-track,
.lead_dialog_edit__content__left::-webkit-scrollbar-thumb,
.lead_dialog_edit__content__right::-webkit-scrollbar-track,
.lead_dialog_edit__content__right::-webkit-scrollbar-thumb,
.lead_dialog_edit__content__menu::-webkit-scrollbar-track,
.lead_dialog_edit__content__menu::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-color: transparent;
}

.lead_dialog_edit__content__left:hover::-webkit-scrollbar-thumb,
.lead_dialog_edit__content__right:hover::-webkit-scrollbar-thumb,
.lead_dialog_edit__content__menu:hover::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-color: transparent;
}
