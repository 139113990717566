.calendarView {
	font-size: 14px;
	position: relative;
	height: calc(100vh - 110px);
	overflow-y: auto;
	padding-bottom: 20px;
}

.calendarView .fc .fc-button-primary {
	background-color: #155d75;
	border-color: #155d75;
}
.calendarView .fc .fc-button-primary:not(:disabled).fc-button-active,
.calendarView .fc .fc-button-primary:not(:disabled):active {
	background-color: #0e475a;
	border-color: #0e475a;
}

.calendarView .fc .fc-header-toolbar {
	display: inline-flex !important;
	position: sticky !important;
	top: 0;
	z-index: 10;
	background-color: #f8f9fa;
	padding: 5px 0 10px 0;
	margin-bottom: 0;
}

.calendarView .fc-header-toolbar .fc-toolbar-title {
	font-size: 16px;
}

.calendarView .fc-header-toolbar .fc-toolbar-chunk button {
	font-size: 13px;
	padding: 6px 15px;
	font-weight: 500 !important;
}

.calendarView .fc-day-today {
	font-weight: bold;
	color: #3978fe;
}

/* .calendarView .fc-scroller.fc-scroller-liquid-absolute {
  height: max-content;
  overflow: hidden !important;
} */

/* 
.calendarView table .fc-col-header {
  position: fixed;
  width: 100%;
  background: #f8f8f8;
  z-index: 10;
  border-top: 1px solid #ddd;
}

.calendarView .fc-daygrid-body.fc-daygrid-body-balanced {
  padding-top: 20px;
}

.calendarView .fc-scroller.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}
.calendarView .fc-scroller.fc-scroller-liquid-absolute::-webkit-scrollbar {
  width: 0px !important;
} */

/* .calendarView  .fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: #000;
  text-decoration: none;
}

.calendarView  .fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #ddd;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 2px;
}

.calendarView .fc-event:hover {
  opacity: 0.85;
} */
