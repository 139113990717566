.subtask_component {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 2px 0 6px;
	color: #000;
}

.subtask_component .subtask_component__content input[type='checkbox'] {
	transform: translateY(3px);
	cursor: pointer;
}

.subtask_component .subtask_component__content input[type='checkbox']:checked + input[type='text'] {
	text-decoration: line-through;
	color: #aaa !important;
}

.subtask_component .subtask_component__content input[type='text'] {
	min-width: 800px;
	margin-left: 6px;
	font-size: 13px;
	font-weight: 400;
	border: none;
	outline: none;
	background-color: transparent;
}

.subtask_component .subtask_component__action i {
	margin-left: 5px;
	font-size: 13px;
	font-weight: 400;
	color: #0000008a;
	cursor: pointer;
	padding: 4px;
	border-radius: 4px;
}
.subtask_component .subtask_component__action i:hover {
	background-color: #0e475a;
	color: #f0ecec;
}
