:root {
	--active-menu: #123456;
}

* {
	list-style-position: inside;
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*background-image: linear-gradient(#dddddd,silver);*/
}
#id {
	width: 100%;
	/*height: 100%;*/
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
video,
image,
iframe {
	max-width: 100% !important;
}
#root {
	z-index: 1;
	position: relative;
}
text {
	font-weight: 300;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serf;
	font-size: 14px;
}

input[type='number'] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

.vertical-text {
	writing-mode: vertical-lr;
	text-orientation: sideways-right;
}

.timeline-sticky {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.node rect,
.node circle,
.node ellipse,
.node polygon {
	stroke: #999;
	fill: #fff;
	stroke-width: 1px;
	padding: 0px;
}

.edgePath path {
	stroke: #333;
	stroke-width: 1.5px;
}

.grecaptcha-badge {
	opacity: 0 !important;
	width: 1px !important;
	height: 1px !important;
	overflow: hidden !important;
}

.tipsy {
	font-size: 10px;
	position: absolute;
	padding: 5px;
	z-index: 100000;
}
.tipsy-inner {
	background-color: #000;
	color: #fff;
	max-width: 200px;
	padding: 5px 8px 4px 8px;
	text-align: center;
}

/* Rounded corners */
.tipsy-inner {
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}
/* Uncomment for shadow */
.tipsy-inner {
	box-shadow: 0 0 5px #000000;
	-webkit-box-shadow: 0 0 5px #000000;
	-moz-box-shadow: 0 0 5px #000000;
}
.tipsy-arrow {
	position: absolute;
	width: 0;
	height: 0;
	line-height: 0;
	border: 5px dashed #000;
}
/* Rules to colour arrows */
.tipsy-arrow-n {
	border-bottom-color: #000;
}
.tipsy-arrow-s {
	border-top-color: #000;
}
.tipsy-arrow-e {
	border-left-color: #000;
}
.tipsy-arrow-w {
	border-right-color: #000;
}

.tipsy-n .tipsy-arrow {
	top: 0px;
	left: 50%;
	margin-left: -5px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-nw .tipsy-arrow {
	top: 0;
	left: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-ne .tipsy-arrow {
	top: 0;
	right: 10px;
	border-bottom-style: solid;
	border-top: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-s .tipsy-arrow {
	bottom: 0;
	left: 50%;
	margin-left: -5px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-sw .tipsy-arrow {
	bottom: 0;
	left: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-se .tipsy-arrow {
	bottom: 0;
	right: 10px;
	border-top-style: solid;
	border-bottom: none;
	border-left-color: transparent;
	border-right-color: transparent;
}
.tipsy-e .tipsy-arrow {
	right: 0;
	top: 50%;
	margin-top: -5px;
	border-left-style: solid;
	border-right: none;
	border-top-color: transparent;
	border-bottom-color: transparent;
}
.tipsy-w .tipsy-arrow {
	left: 0;
	top: 50%;
	margin-top: -5px;
	border-right-style: solid;
	border-left: none;
	border-top-color: transparent;
	border-bottom-color: transparent;
}

.tipsy .name {
	font-size: 1.5em;
	font-weight: bold;
	color: #60b1fc;
	margin: 0;
}
.tipsy .description {
	font-size: 1.2em;
}
.filter__btn {
	background-color: #013659;
	color: #fff;
	font-size: 12px;
	border: none;
	padding: 8px 20px;
	border-radius: 5px;
	cursor: pointer;
	margin: 8px;
	transition: 0.3s;
	border: 1px solid #013659;
}
.filter__btn:hover {
	background-color: #fff;
	color: #013659;
	border: 1px solid #013659;
}

#filter__left {
	transition: 0.4s;
}
.hidden-scroll-y::-webkit-scrollbar {
	display: none;
}
#root {
	position: relative;
}
#wrapper {
	position: relative;
}
#root .right-content {
	position: absolute;
	right: 0px;
	height: calc(100vh - 50px);
	top: 50px;
	z-index: 1999;
}
#root .right-content .MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded {
	margin: 10px 20px !important;
}
#left-menu {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	left: 0px;
	top: 0px;
	background-color: #fff;
	z-index: 3500;
}
#wrapper .icon-bar {
	color: red;
}
/* #left-menu .ant-tooltip-open .ant-menu-title-content {
	background-color: red !important;
	position: relative;
	z-index: 3000;
  } */
.ant-menu-inline-collapsed-tooltip {
	z-index: 2600 !important;
}
.ant-menu-submenu-popup {
	z-index: 2600 !important;
}
.ant-menu-submenu-popup .ant-menu-item:hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
	color: #000 !important;
}
.ant-menu-submenu-popup .ant-menu-item.ant-menu-item-selected {
	background-color: var(--active-menu) !important;
	color: #fff !important;
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
#left-menu .ant-menu-light .ant-menu-item.ant-menu-item-selected {
	background-color: var(--active-menu) !important;
	color: #fff !important;
}
.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
	color: #000 !important;
}
#left-menu .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
	background-color: transparent !important;
	color: #60b1fc !important;
}
#left-menu .ant-menu-sub .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child .ant-menu-title-content {
	border-radius: 4px;
	background-color: var(--active-menu) !important;
	color: #fff !important;
}
#left-menu .ant-menu {
	border: none;
}
/* #left-menu
  .ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: --selectedMenu !important;
} */
#left-menu .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected .ant-menu-submenu-title {
	background-color: var(--active-menu) !important;
	color: #fff !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu.ant-menu-sub.ant-menu-inline
	.ant-menu-submenu.ant-menu-submenu-inline
	.ant-menu-submenu-title {
	color: #000 !important;
	background-color: transparent !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu.ant-menu-sub.ant-menu-inline
	.ant-menu-submenu.ant-menu-submenu-inline
	.ant-menu-submenu-title:hover {
	color: #000 !important;
	background-color: rgba(0, 0, 0, 0.05) !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
	.ant-menu.ant-menu-sub.ant-menu-inline
	.ant-menu-submenu.ant-menu-submenu-inline
	.ant-menu-submenu-title {
	color: #000 !important;
	background-color: transparent !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
	.ant-menu.ant-menu-sub.ant-menu-inline
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected
	.ant-menu-submenu-title {
	color: var(--active-menu) !important;
	background-color: rgba(0, 0, 0, 0.05) !important;
}
/* #left-menu
  .ant-menu-light
  .ant-menu-submenu-open.ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.88) !important;
} */
#left-menu .ant-menu i {
	width: 25px;
	text-align: left;
	font-size: 16px;
}
#left-menu .ant-menu-submenu .ant-menu-item.ant-menu-submenu-selected.ant-menu-item-only-child .ant-menu-submenu-title {
	background-color: var(--active-menu) !important;
	color: #fff !important;
}
#left-menu .ant-menu .ant-menu-item,
#left-menu.ant-menu .ant-menu-submenu,
#left-menu .ant-menu .ant-menu-submenu-title {
	border-radius: 4px !important;
}
#left-menu .ant-menu-item.ant-menu-item-active,
#left-menu .ant-menu-item:not(.ant-menu-item-selected):hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
	color: #000 !important;
}
/* #left-menu .ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: #000 !important;
} */
#left-menu .ant-menu .ant-menu-item:last-child {
	margin-bottom: 5px !important;
}
#left-menu .ant-menu .ant-menu-submenu .ant-menu-item:not(.ant-menu-item-selected):hover {
	background-color: transparent !important;
	color: rgba(0, 0, 0, 0.88) !important;
}
#left-menu::-webkit-scrollbar {
	background-color: transparent;
	/* width: 0px; */
}
/* #left-menu:hover::-webkit-scrollbar {
	width: 8px;
  } */
/* @media (hover: none) {
	#left-menu::-webkit-scrollbar {
	  width: 8px;
	}
	#left-menu::-webkit-scrollbar-thumb {
	  background-color: rgba(0, 0, 0, 0.2);
	}
  } */
#left-menu:hover::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}
#left-menu .ant-menu-sub {
	position: relative;
	--border-color: #cacbd1;
	--border-width: 1px;
	--gap: clamp(16px, 4vh, 1.5em);
	--top-gap: calc(var(--gap) / 2);
}
#left-menu .ant-menu-sub .ant-menu-item {
	padding-left: 0px !important;
	position: relative;
	border-radius: 0px !important;
	width: calc(100% - 30px);
}
#left-menu .ant-menu-sub .ant-menu-item .ant-menu-title-content {
	margin-left: 20px !important;
	padding-left: 15px;
}
#left-menu .ant-menu-sub .ant-menu-item .ant-menu-title-content:hover {
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	color: #000;
}
#left-menu .ant-menu-sub .ant-menu-item {
	margin-inline: 0px !important;
	margin-block: 0px !important;
	margin-left: 35px !important;
}
#left-menu .ant-menu-sub .ant-menu-item:last-child::before {
	content: '';
	border-left: var(--border-width) solid var(--border-color);
	border-bottom: var(--border-width) solid var(--border-color);
	border-bottom-left-radius: 4px;
	position: absolute;
	left: 0;
	top: 0;
	width: calc(var(--gap) / 1.5);
	height: calc(var(--top-gap) + 1em / 2);
}
#left-menu .ant-menu-sub .ant-menu-item:not(:last-child) {
	background-image: linear-gradient(0, var(--border-color), var(--border-color));
	background-repeat: no-repeat;
	background-position: 0 calc(var(--top-gap) + 1em / 2);
	background-size: calc(var(--gap) / 1.5) var(--border-width);
	border-left: var(--border-width) solid var(--border-color);
}
#left-menu .ant-menu-sub .ant-menu-submenu {
	position: relative;
	margin-left: 35px;
}
#left-menu .ant-menu .ant-menu-submenu {
	border-radius: 0px;
}
#left-menu .ant-menu-sub .ant-menu-submenu:last-child:before {
	content: '';
	border-left: var(--border-width) solid var(--border-color);
	border-bottom: var(--border-width) solid var(--border-color);
	border-bottom-left-radius: 4px;
	position: absolute;
	left: 0;
	top: 0;
	width: calc(var(--gap) / 1.5);
	height: calc(var(--top-gap) + 1em / 2);
}
#left-menu .ant-menu-sub .ant-menu-submenu:not(:last-child) {
	background-image: linear-gradient(0, var(--border-color), var(--border-color));
	background-repeat: no-repeat;
	background-position: 0 calc(var(--top-gap) + 1em / 2);
	background-size: calc(var(--gap) / 1.5) var(--border-width);
	border-left: var(--border-width) solid var(--border-color);
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-sub {
	position: relative;
	padding-left: 45px !important;
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-sub .ant-menu-item {
	padding-left: 0px !important;
	position: relative;
	margin-left: 0px !important;
	width: calc(100% + 10px);
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-sub .ant-menu-item .ant-menu-title-content {
	margin-left: 30px;
}
/* #left-menu
	.ant-menu-sub
	.ant-menu-submenu
	.ant-menu-sub
	.ant-menu-item
	.ant-menu-title-content:hover {
	text-decoration: underline;
  } */
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-sub .ant-menu-item:hover {
	background-color: transparent !important;
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title {
	padding-left: 15px !important;
	position: relative;
	margin: 0px !important;
	margin-left: 20px !important;
	width: calc(100% - 30px) !important;
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title:hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
	color: rgba(0, 0, 0, 0.88) !important;
}
#left-menu .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content {
	margin-left: 0px;
}
/* #left-menu
	.ant-menu-sub
	.ant-menu-submenu
	.ant-menu-submenu-title
	.ant-menu-title-content:hover {
	text-decoration: underline;
  } */
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu.ant-menu-submenu-inline
	.ant-menu-submenu-arrow::after {
	transform: rotate(45deg) translateX(2.5px) !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu.ant-menu-submenu-inline
	.ant-menu-submenu-arrow::before {
	transform: rotate(135deg) translateX(-2.5px) !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu-arrow::after {
	transform: rotate(-45deg) translateX(-2.5px) !important;
}
#left-menu
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
	.ant-menu-submenu-arrow::before {
	transform: rotate(45deg) translateX(2.5px) !important;
}
#header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 50px;
	background: #fff;
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important;
	z-index: 2500;
	right: 0px;
	display: flex;
	align-items: center;
}
#header .left {
	/* display: flex; */
}
#root #icon-bar {
	position: fixed;
	top: 0px;
	z-index: 3500;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
/* #root #icon-bar:hover {
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 100%;
} */
#root #icon-bar span {
	padding: 0px;
	width: 30px;
	height: 40px;
	border-radius: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 10px;
}
#root #icon-bar:hover span {
	background-color: rgba(0, 0, 0, 0.1);
}
#root #icon-bar span .line-2,
#root #icon-bar span .line-4 {
	width: 16px;
	height: 2px;
	margin-top: 3px;
	background-color: rgba(0, 0, 0, 0.8);
}
#root #icon-bar span .line-3 {
	margin-top: 3px;
}
#root #icon-bar span .line-1,
#root #icon-bar span .line-3 {
	width: 21px;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.8);
}
#left-menu .logo {
	display: flex;
	align-items: center;
	text-decoration: none;
	justify-content: center;
	color: inherit;
	height: 40px;
	justify-content: start;
	padding-left: 24px;
	margin: 4px;
	/* border-right: 2px solid rgba(0, 0, 0, 0.1) !important; */
	/* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.05) !important; */
}
#left-menu .logo img {
	width: 30px;
	height: 30px;
	border-radius: 4px;
}
#left-menu .logo span {
	color: darkorchid;
	font-size: 24px;
	font-weight: 700;
	margin-left: 15px;
}
#header .tool {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding-right: 20px;
	/* border-left: 1px solid rgba(0, 0, 0, 0.1) !important; */
}
#header .tool .item {
	cursor: pointer;
	color: rgba(0, 0, 0, 0.45);
	transition: all 0.3s linear;
}
#header .tool .item:hover {
	color: #000;
}
#header .tool .tool-right {
	display: flex;
	align-items: center;
}
#header .tool .tool-right .profile {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.ant-dropdown {
	z-index: 2500 !important;
}
.ant-dropdown-menu-title-content a {
	display: flex;
	align-items: center;
}
.ant-dropdown-menu-title-content span {
	margin-left: 10px;
}
#header .tool .tool-right .avatar {
	background: #cccaca;
	width: 35px;
	height: 35px;
	display: flex;
	margin-right: 10px;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
}
#header .tool .tool-right .avatar img {
	width: 100%;
	height: 100%;
	border-radius: 100%;
}

#header .tool .tool-right .item {
	margin-right: 30px;
}
#demo-positioned-menu {
	top: 34px !important;
}
#demo-positioned-menu .MuiList-padding {
	padding-top: 0px;
	padding-bottom: 0px;
}
#demo-positioned-menu .MuiPaper-root.MuiMenu-paper {
	left: auto !important;
	right: 166px;
}
#demo-positioned-menu .MuiMenu-paper {
	width: 290px;
}
#demo-positioned-menu .MuiMenuItem-root {
	white-space: normal;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}
#demo-positioned-menu .MuiMenuItem-root:not(:last-child) {
	border-bottom: 1px solid #000;
}
#demo-positioned-menu .MuiMenuItem-root:hover {
	background-color: transparent;
}
#header .MuiButton-root {
	min-width: auto;
	padding: 0px;
}
/* .MuiDialog-paper {
  margin: 0px !important;
}
.MuiDialog-scrollPaper {
  margin-top: 6vh !important;
} */
.MuiDialogTitle-root {
	/* background-color: #60b1fc !important; */
}
/* .MuiDialogTitle-root button svg {
  color: #fff !important;
}
.MuiDialogTitle-root
  .MuiButtonBase-root.MuiButton-root.MuiButton-root-17.MuiButton-contained {
  background-color: #fff;
  color: #000;
}
body .MuiButton-containedSecondary,
body .MuiButton-containedPrimary {
  background-color: #60b1fc;
  color: #fff;
}
body .MuiButton-containedSecondary:hover,
body .MuiButton-containedPrimary:hover {
  background-color: #60b1fc;
  opacity: 0.8;
} */
#login {
	width: 100%;
	overflow: hidden;
}

#login .MuiDialog-scrollPaper {
	/* margin-top: 0px !important;
	margin-bottom: 0px !important; */
	/* margin: 15vh 10vh; */
}
#login .MuiDialog-paper {
	overflow-x: hidden;
}
#login .MuiDialogContent-root {
	padding: 0px;
}
#login .MuiDialog-container {
	height: 100%;
}
#login .login__wrapper {
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}
#login .login__wrapper .MuiGrid-container {
	height: 100%;
}
#login .left {
	background-image: url(https://pos.goodapp.vn/images/logoLogin.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
#login .right {
	padding: 80px 50px !important;
}
@media screen and (min-width: 960px) and (max-width: 1024px) {
	#login .right {
		padding: 80px 20px !important;
	}
}
@media screen and (max-width: 959px) {
	#login .left {
		display: none;
	}
	#login .right {
		width: 60%;
		padding: 80px 0px !important;
		margin: auto;
	}
}
@media screen and (max-width: 570px) {
	#login .left {
		display: none;
	}
	#login .right {
		width: 90%;
		padding: 80px 0px !important;
		margin: auto;
	}
}

#login .right .login {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
#login .right h4 {
	font-size: 36px;
	font-weight: 400;
}
#login .right p {
	margin-top: 27px;
	color: #747474;
	font-size: 18px;
	font-weight: 400;
}
#login .MuiDialog-scrollPaper {
	margin: 0vh;
}
#login .login {
}
#login .login__field {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
#login label {
	font-size: 20px;
	font-weight: 500;
}
#login .login__input {
	padding: 12px 20px;
	margin-top: 10px;
	font-size: 18px;
	border-radius: 10px;
	border: 1px solid #dadada;
	font-weight: 400;
	outline: none;
	font-size: 16px;
	font-weight: 400;
}
#login .login__input::-webkit-input-placeholder {
	font-weight: 400;
	outline: none;
	font-size: 16px;
	font-weight: 400;
}
#login .login__input::-moz-input-placeholder {
	font-weight: 400;
	outline: none;
	font-size: 16px;
	font-weight: 400;
}
#Login .login .MuiFormControlLabel-root {
	font-size: 12px;
}
#login .login__submit {
	background: linear-gradient(#5fadfb 0%, #6090fc 37.99%, #605df9 63.68%, #9a8cfc 100%);
	font-size: 18px;
	margin-top: 20px;
	padding: 14px 20px;
	border-radius: 10px;
	border: none;
	text-transform: uppercase;
	font-weight: 400;
	display: flex;
	align-items: center;
	width: 100%;
	color: #fff;
	cursor: pointer;
	transition: 0.2s;
}
.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6a679e;
	outline: none;
}
.button__icon {
	font-size: 24px;
	margin-left: auto;
	color: #7875b5;
}
#login .MuiFormControlLabel-root span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
	font-size: 18px;
	font-weight: 400;
}
#login .MuiCheckbox-colorSecondary.Mui-checked {
	background: linear-gradient(#5fadfb 0%, #6090fc 37.99%, #605df9 63.68%, #9a8cfc 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.glow-on-hover {
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 8px;
}
.glow-on-hover:before {
	content: '';
	background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 2px);
	animation: glowing 20s linear infinite;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	border-radius: 8px;
}

.glow-on-hover:active {
	color: #000;
}

.glow-on-hover:hover::after {
	background: #fff !important;
}
.glow-on-hover:hover .button__text {
	background: linear-gradient(#5fadfb 0%, #6090fc 37.99%, #605df9 63.68%, #9a8cfc 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.glow-on-hover:hover:before {
	opacity: 1;
}
.glow-on-hover:after {
	z-index: -1;
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(#5fadfb 0%, #6090fc 37.99%, #605df9 63.68%, #9a8cfc 100%);
	left: 0;
	top: 0;
	border-radius: 8px;
}
#left-menu::-webkit-scrollbar-track {
	background-color: transparent;
}
@-webkit-keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
@-webkit-keyframes move {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
@-webkit-keyframes colorChange {
	0% {
		border-color: #007bff;
	}
	5% {
		border-color: #6610f2;
	}
	10% {
		border-color: #6f42c1;
	}
	15% {
		border-color: #e83e8c;
	}
	20% {
		border-color: #dc3545;
	}
	25% {
		border-color: #fd7e14;
	}
	30% {
		border-color: #ffc107;
	}
	35% {
		border-color: #28a745;
	}
	40% {
		border-color: #20c997;
	}
	45% {
		border-color: #17a2b8;
	}
	50% {
		border-color: #6c757d;
	}
	55% {
		border-color: #343a40;
	}
	60% {
		border-color: #007bff;
	}
	65% {
		border-color: #6c757d;
	}
	70% {
		border-color: #28a745;
	}
	75% {
		border-color: #17a2b8;
	}
	80% {
		border-color: #ffc107;
	}
	85% {
		border-color: #dc3545;
	}
	90% {
		border-color: #343a40;
	}
	95% {
		border-color: #28a745;
	}
	100% {
		border-color: #20c997;
	}
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scroll_bar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll_bar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* loading */
.round-loading {
	animation: round-loading 0.5s ease-in-out infinite;
}
@keyframes round-loading {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Empty text */
.empty-text {
	font-size: 14px;
	font-weight: 600;
	color: #ccc;
}

/* Empty data table */
.empty-data-table {
	font-size: 14px;
	font-weight: 600;
	color: #ccc;
	padding: 20px;
}

/* Button */
.btn {
	color: #fff;
	border: none;
	padding: 5px 10px;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 34px;
	box-shadow: rgb(0 0 0 / 5%) 0px 20px 27px 0px;
	transition: all 0.3s ease-in-out;
}
.btn > svg + span {
	margin-left: 8px;
}
.btn:disabled,
.btn:disabled:hover {
	background-color: #ccc;
	color: #fff;
	cursor: not-allowed;
}

.btn-primary {
	color: var(--primary-color);
	background-color: #fff;
}
.btn-primary:hover {
	color: var(--secondary-color);
}
.btn-primary:focus-visible {
	outline: none !important;
	background-color: #19a1b8;
}

.btn-primary-2 {
	color: #fff;
	background-color: #4abfd3;
}
.btn-primary-2:hover {
	background-color: #19a1b8;
}
.btn-primary-2:focus-visible {
	outline: none !important;
	background-color: #19a1b8;
}

.btn-edit {
	color: var(--primary-color);
	background-color: #fff;
}

.btn-edit:hover {
	opacity: 0.8;
}

.btn-delete,
.btn-danger {
	background-color: #f44336;
}

.btn-delete:hover,
.btn-danger:hover {
	background-color: #d32f2f;
}

.btn-grey {
	background-color: #898c8d;
}
.btn-grey:hover {
	background-color: #6d7071;
}
.btn-grey:focus-visible {
	outline: none !important;
	background-color: #6d7071;
}

.btn-text {
	background-color: transparent;
	box-shadow: none;
	color: var(--primary-color);
	margin-left: 0;
	transition: all 0.3s ease-in-out;
}

.btn-text:hover {
	opacity: 0.8;
}

.btn-white {
	background-color: #fff;
	color: var(--primary-color);
}
.btn-white:hover {
	opacity: 0.8;
}

.btn-list {
	color: var(--primary-color);
	background-color: #fff;
}
.btn-list:hover {
	opacity: 0.8;
}

.btn-fill {
	background-color: var(--primary-color);
	color: #fff;
}
.btn-fill:hover {
	background-color: var(--secondary-color);
}

.btn-transparent {
	background-color: transparent;
	color: #333;
	box-shadow: none;
	justify-content: left;
}
.btn-transparent:hover {
	background-color: #f5f5f5;
}

.btn-outline {
	background-color: transparent;
	border: 1px solid var(--secondary-color);
	color: var(--secondary-color);
}

.btn-outline:hover {
	border-color: var(--primary-color);
	color: var(--primary-color);
}

.btn-outline:focus-visible {
	border-color: var(--primary-color);
	color: var(--primary-color);
}

/* Color */
:root {
	--success-color: #28a745;
	--info-color: #17a2b8;
	--warning-color: #ffc107;
	--danger-color: #dc3545;
	--primary-color: #0e475a;
	--secondary-color: #81a8b9;
	--gray-color: #6c757d;

	/* light */
	--success-color-light: #3cff9c;
	--info-color-light: #1fe0ff;
	--warning-color-light: #faff07;
	--danger-color-light: #ff895f;
	--primary-color-light: #25acff;
	--secondary-color-light: #b1e7ff;
	--gray-color-light: #f8f9fa;

	--bg-hover: #e7f1ff;
}

.error-text {
	color: var(--danger-color);
	font-weight: 500;
}

input.MuiAutocomplete-input::placeholder {
	color: #333;
	opacity: 0.8;
}

/* Table Cell */
.contact_cell {
	border-radius: 4px;
	padding: 4px 8px;
	cursor: pointer;
	text-decoration: none;
	color: inherit;

	display: flex;
	align-items: center;
	gap: 8px;
	width: fit-content;
	border: 1px solid transparent;
}

.contact_cell svg {
	width: 20px;
	transition: transform 0.3s ease-in-out;
}

div[role='row']:hover .contact_cell {
	background-color: white;
}

.contact_cell:hover {
	border-color: var(--primary-color-light);
}

@keyframes left-vibrate-ani {
	0% {
		transform: scale(1) rotate(0deg) translateX(0px);
	}
	25% {
		transform: scale(1.1) rotate(-15deg) translateX(-4px);
	}
	50% {
		transform: scale(1.1) rotate(0deg) translateX(-4px);
	}
	75% {
		transform: scale(1.1) rotate(-15deg) translateX(-4px);
	}
	100% {
		transform: scale(1) rotate(0deg) translateX(0px);
	}
}

.contact_cell:hover svg {
	animation: left-vibrate-ani 0.8s ease-in-out;
}
