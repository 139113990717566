.container {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.table_container {
	background-color: white;
	border-radius: 8px;
	overflow: auto;
	font-size: 13px !important;
	display: block;
	max-width: 100%;
}

.table_wrapper {
	display: block;
	max-width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	border-bottom: 1px solid black;
}

.resizer {
	display: inline-block;
	width: 3px;
	opacity: 0;
	background-color: var(--primary-color-light);
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
	touch-action: none;
	transition: all 0.3s ease-in-out;
}

.resizer:hover {
	cursor: col-resize;
}

.header_cell {
	padding: 12px;
	font-weight: 500;
}

.header_cell.resizeable:hover {
	backdrop-filter: brightness(0.9);
}

.header_cell:hover .resizer {
	opacity: 1;
}

.body {
	position: relative;
}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.row:nth-child(odd) {
	background-color: var(--gray-color-light);
}

.row {
	width: 100% !important;
	display: flex;
	align-items: center;
}

.row:hover {
	background-color: var(--bg-hover);
	color: var(--primary-color-light);
}

.row .cell {
	padding: 12px;
	overflow: hidden;
}

.row_actions {
	display: flex;
	border-radius: 4px;

	float: right;
	position: sticky;
	z-index: 1;
	right: 8px;

	opacity: 0;
}

.row:hover .row_actions {
	opacity: 1;
}

.row_actions button {
	border-radius: 0;
}
.row_actions button:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}
.row_actions button:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.checkbox_cell {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbar_container {
	display: flex;
	justify-content: space-between;
}

.filter_container {
	position: absolute;
	top: 100%;
	margin-top: 8px;
	transition: all 0.2s ease-in-out;
}

.toolbar_left,
.toolbar_right {
	display: flex;
	gap: 0.75rem;
}

button.v_button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 8px;
}

.v_button svg {
	width: 20px;
	transition: transform 0.3s ease-in-out;
}

.toolbar_pagination {
	background-color: white;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.toolbar_pagination button {
	border-radius: 0;
}

.toolbar_pagination button:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.toolbar_pagination button:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.toolbar_pagination .pagination_label {
	width: 120px;
	cursor: default;
}

.toolbar_pagination .pagination_label div {
	border-radius: 0;
}

.col_settings_container {
	display: flex;
	gap: 16px;
}

.list_wrapper {
	width: 100%;
}

.list_title {
	font-weight: 500;
}

.v_modal_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.v_modal_title span {
	font-weight: 500;
}

.v_modal_actions {
	display: flex;
	align-items: center;
	gap: 8px;
}

.tag_input_container {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}
