.MuiDialog-scrollPaper {
	align-items: start !important;
	margin: 3vh 10vh;
}

.dialog_title {
	padding-bottom: 0;
}

.box_header_dialog {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 8px 24px 0;
}
.header_add_dialog {
	padding: 0;
	font-size: 18px;
	color: #121212;
	font-weight: 550;
}
.btn_close_dialog {
	cursor: pointer;
}
.field_input {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.field_input_title {
	font-size: 13px;
	font-weight: 600;
	color: #2e2e2e;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.field_input_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ccc;
}
.field_input_container textarea {
	border: none;
	padding: 0;
}
.field_input_container textarea:focus {
	box-shadow: none;
}
.field_input_container:focus-within {
	outline: none;
	border-bottom: 2px solid var(--secondary-color);
}
.field_input_content_input {
	color: #67717e;
	background-color: transparent;
	font-size: 12px;
	font-weight: 500;
	padding: 5px 0;
	border: none;
	width: 100%;
	height: 38px;
}
.field_input_content_input:focus-visible {
	outline: none;
	border-bottom: none;
}
.field_input_content_input:focus {
	outline: none;
}

.field_input_error {
	font-size: 12px;
	color: #ed5c6a;
}

.add_room_group {
	display: flex;
	justify-content: start;
	align-items: center;
	margin-bottom: 10px;
}
.add_room_group_title_title {
	font-size: 12px;
	font-weight: bold;
	color: #2e2e2e;
	padding: 10px 10px 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.group_btn_add {
	margin: 20px 0;
}

/* Custom css dialog */
.dialog_item {
	min-width: 500px !important;
	margin-top: 48px !important;
}

/* check start */
.start_check_dialog_field {
	font-size: 14px;
	color: #ed5c6a;
	margin-left: 10px;
}

div.field-select-api__control {
	border: none;
	border-bottom: 1px solid #ccc;
	border-radius: 0;
	box-shadow: none;
}
div.field-select-api__control--is-disabled {
	background-color: transparent;
}

div.field-select-api__control:focus-within {
	outline: none;
	border-bottom: 2px solid var(--secondary-color);
}

div.field-select-api__value-container,
div.field-select-api__indicator {
	padding: 0;
}

div.field-select-api__single-value,
div.field-select-api__placeholder,
div.field-select-api__input {
	font-size: 12px;
	color: #67717e;
	margin: 0;
}

span.field-select-api__indicator-separator {
	display: none;
}

div.field-select-api__option {
	font-size: 12px;
	color: #67717e;
}

div.field-select-api__multi-value {
	border-radius: 8px;
	overflow: hidden;
}

div.field-select-api__multi-value__label {
	font-size: 12px;
}

div.field-select-api__multi-value__remove:hover {
	cursor: pointer;
}

span.rti--tag,
input.rti--input {
	font-size: 12px;
	line-height: 1;
	display: flex;
	align-items: center;
}

span.rti--tag button {
	margin-left: 4px;
	padding: 0;
}

div.rti--container {
	width: 100%;
	border: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}

div.rti--container:focus-within {
	box-shadow: none;
}

input.rti--input {
	flex: 1;
}
